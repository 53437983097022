import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { format } from 'date-fns'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { DAYS_IN_A_WEEK } from 'src/Resources/CompanyProfile/Data'
import { isIE } from 'src/common/DetectBrowser'

const styles = {
  workHourList: {
    paddingLeft: 0,
    paddingBottom: 0,
  },
  workDayItem: {
    float: 'left',
    width: '6em',
  },
  workHourItem: {
    width: '11em',
    float: 'left',
  },
}

function BusinessHours({ classes, businessHours, paddingTop }) {
  return (
    <List disablePadding={true}>
      {buildBusinessHours(classes, businessHours, paddingTop)}
    </List>
  )
}

function buildBusinessHours(classes, businessHours, paddingTop) {
  if (typeof buildBusinessHours !== 'function') {
    return null
  }

  let businessHoursRows = []
  DAYS_IN_A_WEEK.forEach(day => {
    if (businessHours[day]) {
      businessHoursRows.push(
        buildBusinessHoursRow(classes, day, businessHours[day], paddingTop)
      )
    }
  })

  return businessHoursRows
}

function buildBusinessHoursRow(classes, dayString, singleDay, paddingTop) {
  return (
    <ListItem
      key={dayString}
      className={classes.workHourList}
      style={{ paddingTop: paddingTop }}
    >
      <ListItemText
        primary={dayString}
        primaryTypographyProps={{
          variant: 'body2',
          className: classes.workDayItem,
        }}
        secondary={`
                ${formatTime(singleDay.openTime)} to ${formatTime(
          singleDay.closeTime
        )}
              `}
        secondaryTypographyProps={{
          variant: 'body2',
          className: classes.workHourItem,
          color: 'default',
        }}
      />
    </ListItem>
  )
}

function formatTime(time) {
  if (time.length < 5) {
    time = '0' + time
  }
  const curDate = new Date()
  try {
    if (isIE()) {
      return format(new Date(curDate.toDateString() + ' ' + time), 'h:mm a')
    }
    return format(
      new Date(
        curDate.toLocaleDateString() +
          ' ' +
          time +
          curDate.toTimeString().substring(8)
      ),
      'h:mm a'
    )
  } catch (err) {
    return time
  }
}

BusinessHours.propTypes = {
  businessHours: PropTypes.object,
}

BusinessHours.defaultProps = {
  paddingTop: 'default',
}

export default withStyles(styles)(BusinessHours)
