import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { CancelSaveButtons, SocialMediaEditForm } from 'housecall-ui'
import {
  getSocialNetworkHost,
  stripPathFromURL,
  getURLOfSocialNetwork,
} from './SocialIconsHelper'
import { isPresent } from 'src/common/ObjectHelper'

export default class SocialIconsEditDialog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      facebook: '',
      twitter: '',
      instagram: '',
    }
  }

  componentDidMount() {
    this.mapLinksArrayToState()
  }

  mapLinksArrayToState() {
    const { links } = this.props
    links.map(link => {
      this.setState({ [link.name]: getURLOfSocialNetwork(link.values) })
    })
  }

  mapStateToLinksArray() {
    let socialLinks = []
    Object.keys(this.state).map(key => {
      const socialURL = isPresent(this.state[key])
        ? getSocialNetworkHost(key) + stripPathFromURL(this.state[key])
        : ''

      socialLinks.push({
        name: key,
        values: `{ "url": "${socialURL}" }`,
      })
    })
    return socialLinks
  }

  handleCancelClick() {
    this.mapLinksArrayToState()
    this.props.onClose()
  }

  handleFormChange(socialName, event) {
    this.setState({ [socialName]: event.target.value })
  }

  render() {

    return (
      <Dialog open={this.props.open} onClose={() => this.handleCancelClick()}>
        <DialogTitle>Social links</DialogTitle>
        <DialogContent>
          <SocialMediaEditForm
            links={this.state}
            onFormChange={(socialName, event) => this.handleFormChange(socialName, event)}
          />
        </DialogContent>
        <DialogActions>
          <CancelSaveButtons
            saveButtonVariant="text"
            onCancelClick={() => this.handleCancelClick()}
            onSaveClick={() =>
              this.props.onSubmit({
                external_connections: this.mapStateToLinksArray(),
              })
            }
          />
        </DialogActions>
      </Dialog>
    )
  }
}

SocialIconsEditDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  links: PropTypes.arrayOf(PropTypes.object),
}

SocialIconsEditDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
  links: [],
}
