import React from 'react'
import PropTypes from 'prop-types'
import Contact from '../../Footers/Contact'
import BusinessHours from '../../Footers/BusinessHours'

function ContactUs({ organization, businessHours }) {
  return (
    <div>
      <Contact
        printableAddress={organization.printable_address}
        phoneNumber={organization.phone_number}
        email={organization.email}
      />
      <BusinessHours businessHours={businessHours} paddingTop="0" />
    </div>
  )
}

ContactUs.propTypes = {
  organization: PropTypes.object.isRequired,
  businessHours: PropTypes.arrayOf(PropTypes.object).isRequired,
}

ContactUs.defaultProps = {
  organization: {},
  businessHours: [],
}

export default ContactUs
