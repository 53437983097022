import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import OrderTableRow from './OrderTableRow'
import AccountIcon from './Icons/AccountIcon'
import TimeIcon from './Icons/TimeIcon'
import LocationIcon from './Icons/LocationIcon'
import Typography from '@material-ui/core/Typography'

const styles = {
  orderSummary: {
    float: 'left',
  },
  table: {
    tableLayout: 'auto',
  },
}

function OrderSummaryComplete({
  classes,
  addressLine1,
  addressLine2,
  time,
  date,
  customerName,
  phoneNumber,
  email,
}) {
  return (
    <Grid item className={classes.orderSummary}>
      <Table className={classes.table}>
        <TableBody>
          <OrderTableRow icon={<AccountIcon />}>
            <Typography>{customerName}</Typography>
            <Typography>{phoneNumber}</Typography>
            <Typography>{email}</Typography>
          </OrderTableRow>
          <OrderTableRow icon={<LocationIcon />}>
            <Typography>{addressLine1}</Typography>
            <Typography>{addressLine2}</Typography>
          </OrderTableRow>
          <OrderTableRow icon={<TimeIcon />}>
            <Typography>{date}</Typography>
            <Typography>{time}</Typography>
          </OrderTableRow>
          {/* <OrderTableRow key={3} cell={address} icon={<LocationIcon />} /> */}
        </TableBody>
      </Table>
    </Grid>
  )
}

export default withStyles(styles)(OrderSummaryComplete)
