import React from 'react'
import PropTypes from 'prop-types'
import { isPresent } from '../../../common/ObjectHelper'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  image: {
    maxHeight: 80,
    maxWidth: 175,
  },
}

function CompanyLogoOrName({ classes, variant, logoUrl, companyName }) {
  let logo = (
    <Typography variant={variant === 'large' ? 'h5' : 'subtitle1'}>
      {companyName}
    </Typography>
  )

  if (isPresent(logoUrl)) {
    logo = <img className={classes.image} src={logoUrl} />
  }
  return <a href="/">{logo}</a>
}

CompanyLogoOrName.propTypes = {
  logoUrl: PropTypes.string,
  companyName: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'large']),
}

CompanyLogoOrName.defaultProps = {
  companyName: '',
  logoUrl: '',
  variant: 'large',
}

export default withStyles(styles)(CompanyLogoOrName)
