import React from 'react'
import PropTypes from 'prop-types'
import SectionHeader from './SectionHeader'
import { EditAndDelete } from 'housecall-ui'

function CanEditSectionHeader({
  id,
  title,
  isEditing,
  onEditClick,
  deleteEnable,
  onDeleteClick,
}) {
  return (
    <SectionHeader id={id} title={title}>
      <EditAndDelete
        canDelete={deleteEnable}
        canEdit={true}
        editting={isEditing}
        onEditClick={() => onEditClick()}
        onDeleteClick={() => onDeleteClick()}
      />
    </SectionHeader>
  )
}

CanEditSectionHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  isEditing: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func,
  deleteEnable: PropTypes.bool,
}

CanEditSectionHeader.defaultProps = {
  deleteEnable: false,
}

export default CanEditSectionHeader
