import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Hidden } from '@material-ui/core'
import Section from '../../CompanyProfile/Sections/Section'
import ContactUs from './ContactUs'
import DoneSection from './DoneSection'
import OrderSummarySection from './OrderSummarySection'
import CompanyLogoOrName from '../../CompanyProfile/CompanyLogo/CompanyLogoOrName'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  contactSection: {
    [theme.breakpoints.up('md')]: {
      borderLeft: 'solid 1px #c7c8c9',
    }
  },
})

function CompleteModal({ classes, organization, businessHours }) {
  return (
    <Grid container alignItems="center" justify="center" spacing={32}>
      <Grid item>
        <CompanyLogoOrName
          logoUrl={organization.logo_url}
          companyName={organization.company_name}
        />
      </Grid>
      <Grid
        item
        xs={12}
        spacing={32}
        container
        direction="row"
        justify="center"
      >
        <Hidden mdUp>
          <Grid item xs={12} md={6}>
            <OrderSummarySection justify={'flex-start'} />
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item xs={12} md={6}>
            <OrderSummarySection justify={'flex-end'} />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6} className={classes.contactSection}>
          <Section title="Contact us" showDivider={false}>
            <ContactUs
              organization={organization}
              businessHours={businessHours}
            />
          </Section>
        </Grid>
        <DoneSection />
      </Grid>
    </Grid>
  )
}

CompleteModal.propTypes = {
  organization: PropTypes.object.isRequired,
  businessHours: PropTypes.arrayOf(PropTypes.object).isRequired,
}

CompleteModal.defaultProps = {
  organization: {},
  businessHours: [],
}

export default withStyles(styles)(CompleteModal)
