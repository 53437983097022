import { createSelector } from 'reselect'
import { receivedServerData } from 'data-fetcher'
import { isPresent } from 'src/common/ObjectHelper'

export const SERVICE_LOCATION = 'serviceLocation'

export const updateServiceLocationState = data => receivedServerData(data, SERVICE_LOCATION)

export const getServiceLocation = createSelector(
  state => state[SERVICE_LOCATION],
  serviceLocation => {
    return serviceLocation || {}
  }
)

export const getServiceStreetAddress = createSelector(
  getServiceLocation,
  serviceLocation => {
    return serviceLocation.street || ''
  }
)

export const getServiceCity = createSelector(
  getServiceLocation,
  serviceLocation => {
    return serviceLocation.city || ''
  }
)

export const getServiceState = createSelector(
  getServiceLocation,
  serviceLocation => {
    return serviceLocation.state || ''
  }
)

export const getServicePostalCode = createSelector(
  getServiceLocation,
  serviceLocation => {
    return serviceLocation.postal_code || ''
  }
)

export const getServiceCountry = createSelector(
  getServiceLocation,
  serviceLocation => {
    return serviceLocation.country || ''
  }
)

export const getServiceLatitude = createSelector(
  getServiceLocation,
  serviceLocation => {
    return serviceLocation.latitude || 0
  }
)

export const getServiceLongitude = createSelector(
  getServiceLocation,
  serviceLocation => {
    return serviceLocation.longitude || 0
  }
)

export const getPrintableAddressLine2 = createSelector(
  getServiceLocation,
  serviceLocation => {
    let addressLineTwo = serviceLocation.city || ''

    if(isPresent(addressLineTwo)) {
      addressLineTwo = addressLineTwo + ', '
    }

    addressLineTwo = addressLineTwo + (serviceLocation.state || '')

    if(isPresent(addressLineTwo)) {
      addressLineTwo = addressLineTwo + ' '
    }
    
    addressLineTwo = addressLineTwo + (serviceLocation.postal_code || '')

    return addressLineTwo || ''
  }
)