import { format, parseISO } from 'date-fns/esm'
import { isPresent } from './ObjectHelper'

export const formatDateAndStartEndTime = (startTime, endTime) => {
  if (isPresent(startTime) && isPresent(endTime)) {
    let formattedStartString = format(
      parseISO(startTime),
      'iii, MMM d, yyyy @ h:mm'
    )
    let formattedEndString = formatTime(endTime)
    return formattedStartString + '-' + formattedEndString
  }
}

export const formatStartAndEndTime = (startTime, endTime) => {
  if (isPresent(startTime) && isPresent(endTime)) {
    let formattedStartString = formatTime(startTime)
    let formattedEndString = formatTime(endTime)
    return formattedStartString + '-' + formattedEndString
  }
}

export const formatDate = startTime => {
  if (isPresent(startTime)) {
    let formattedDateString = format(parseISO(startTime), 'iii, MMM d, yyyy')
    return formattedDateString
  }
}

export const formatTime = time => {
  if (isPresent(time)) {
    return format(parseISO(time), 'h:mm aa')
  }
}