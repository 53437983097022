import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import SectionLayout from './SectionLayout'
import {
  LongAddContentButton,
  CancelSaveButtons,
  EditAndDelete,
} from 'housecall-ui'
import { withStyles } from '@material-ui/core/styles'
import { isPresent } from 'src/common/ObjectHelper'
import { Grid } from '@material-ui/core'

const styles = theme => ({
  footer: {
    paddingTop: 16,
  },
  sectionBackground: {
    backgroundColor: theme.palette.background.main,
  },
  childrenPadding: {
    padding: 12,
  },
  editDeletePosition: {
    position: 'absolute',
    right: 0,
  },
})

function SectionWithoutHeader({
  classes,
  backgroundVariant,
  children,
  showDivider,
  canEdit,
  isEditing,
  onSaveClick,
  isSaving,
  onEditClick,
  onCancelClick,
  onDeleteClick,
  showAddButton,
  addButtonText,
  onAddButtonClick,
  deleteEnable,
  disabled,
}) {
  return (
    <SectionLayout
      showDivider={showDivider}
      bodyContent={
        <Fragment>
          <Grid
            container
            className={
              backgroundVariant == 'light' ? classes.sectionBackground : ''
            }
          >
            <Grid item xs className={classes.childrenPadding}>
              {children}
            </Grid>
            {canEdit && (
              <Grid item className={classes.editDeletePosition}>
                <EditAndDelete
                  canDelete={deleteEnable}
                  canEdit={canEdit}
                  editting={isEditing}
                  onEditClick={() => onEditClick()}
                  onDeleteClick={() => onDeleteClick()}
                />
              </Grid>
            )}
          </Grid>
          {showAddButton && (
            <LongAddContentButton
              buttonText={addButtonText}
              onClick={() => {
                if (isPresent(onAddButtonClick)) {
                  onAddButtonClick()
                }
              }}
            />
          )}
          {canEdit && isEditing && (
            <div className={classes.footer}>
              <CancelSaveButtons
                isSaving={isSaving}
                onCancelClick={() => {
                  if (isPresent(onCancelClick)) {
                    onCancelClick()
                  }
                }}
                onSaveClick={() => {
                  if (isPresent(onSaveClick)) {
                    onSaveClick()
                  }
                }}
                disabled={disabled}
              />
            </div>
          )}
        </Fragment>
      }
    />
  )
}

SectionWithoutHeader.propTypes = {
  backgroundVariant: PropTypes.oneOf(['default', 'light']),
  showDivider: PropTypes.bool,
  showAddButton: PropTypes.bool,
  canEdit: PropTypes.bool,
  isSaving: PropTypes.bool,
  isEditing: PropTypes.bool,
  onEditClick: PropTypes.func,
  onAddClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  deleteEnable: PropTypes.bool,
  disabled: PropTypes.bool,
}

SectionWithoutHeader.defaultProps = {
  backgroundVariant: 'default',
  showDivider: true,
  canEdit: false,
  isEditing: false,
  isSaving: false,
  showAddButton: false,
  deleteEnable: false,
}

export default withStyles(styles)(SectionWithoutHeader)
