import {
  receivedCompanyProfileData,
  getCompanyProfile,
} from 'src/Resources/CompanyProfile/Data'

export function didUpdateContent(contentKey, content) {
  return (dispatch, getState) => {
    let companyProfile = Object.assign({}, getCompanyProfile(getState()))
    companyProfile[contentKey] = content
    dispatch(receivedCompanyProfileData(companyProfile))
  }
}
