import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List, ListItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  selectIconImage,
  capitalizeFirstLetter,
  isURLPresent,
  getURLOfSocialNetwork,
} from './SocialIconsHelper'

const styles = {
  iconList: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    width: 150,
  },
  iconListItem: {
    paddingLeft: 0,
  },
}

function SocialIcons({ classes, links }) {
  return (
    <List className={classes.iconList}>
      {links.map(link => {
        if (isURLPresent(link)) {
          return (
            <ListItem
              className={classes.iconListItem}
              key={`${link.name}-social-icon`}
            >
              <a href={getURLOfSocialNetwork(link.values)} target="_blank">
                <img
                  src={selectIconImage(link.name)}
                  alt={capitalizeFirstLetter(link.name)}
                  title={capitalizeFirstLetter(link.name)}
                  width={30}
                />
              </a>
            </ListItem>
          )
        }
      })}
    </List>
  )
}

SocialIcons.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
}

SocialIcons.defaultProps = {
  links: [],
}

export default withStyles(styles)(SocialIcons)
