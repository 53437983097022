import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isBlank } from 'src/common/ObjectHelper'
import SectionWithoutHeader from '../../CompanyProfile/Sections/SectionWithoutHeader'
import SocialIcons from './SocialIcons'
import SocialIconsEditDialog from './SocialIconsEditDialog'
import { updateExternalConnections } from './Redux/Actions'
import { getSocialNetworks } from '../../../Resources/CompanyProfile/ExternalConnections/Data'
import { isURLPresent } from './SocialIconsHelper'

@connect(
  state => ({
    socialLinks: getSocialNetworks(state),
  }),
  { updateExternalConnections }
)
export default class SocialIconsParent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isDialogOpen: false,
    }
  }

  anyLinksPresent(links) {
    if (isBlank(links)) {
      return false
    }

    for (const link of links) {
      if (isURLPresent(link)) {
        return true
      }
    }

    return false
  }

  handleOpenDialog() {
    this.setState({ isDialogOpen: true })
  }

  handleCloseDialog() {
    this.setState({ isDialogOpen: false })
  }

  handleSubmitDialog(links) {
    this.props.updateExternalConnections(links)
    this.handleCloseDialog()
  }

  render() {
    const { socialLinks, inEditMode } = this.props

    return (
      <Fragment>
        <SectionWithoutHeader
          showDivider={false}
          addButtonText="ADD SOCIAL LINKS"
          showAddButton={inEditMode && !this.anyLinksPresent(socialLinks)}
          id="social-links"
          onAddButtonClick={() => this.handleOpenDialog()}
          canEdit={inEditMode && this.anyLinksPresent(socialLinks)}
          onEditClick={() => this.handleOpenDialog()}
        >
          {this.anyLinksPresent(socialLinks) && (
            <SocialIcons links={socialLinks} />
          )}
        </SectionWithoutHeader>
        <SocialIconsEditDialog
          links={socialLinks}
          open={this.state.isDialogOpen}
          onClose={() => this.handleCloseDialog()}
          onSubmit={links => this.handleSubmitDialog(links)}
        />
      </Fragment>
    )
  }
}

SocialIconsParent.propTypes = {
  inEditMode: PropTypes.bool,
}

SocialIconsParent.defaultProps = {
  inEditMode: false,
}
