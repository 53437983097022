import InstagramIcon from '../../../images/instagram-icon.png'
import FacebookIcon from '../../../images/fb-icon.png'
import TwitterIcon from '../../../images/twitter-icon.png'
import { isPresent } from 'src/common/ObjectHelper'

export const getSocialNetworkHost = socialName => {
  return `https://www.${socialName}.com/`
}

export const selectIconImage = key => {
  switch (key) {
    case 'facebook':
      return FacebookIcon
    case 'twitter':
      return TwitterIcon
    case 'instagram':
      return InstagramIcon
    default:
      return null
  }
}

export const capitalizeFirstLetter = socialNetwork => {
  return socialNetwork.charAt(0).toUpperCase() + socialNetwork.slice(1)
}

export const stripPathFromURL = url => {
  if (url.slice(-1) === '/') {
    url = url.substring(0, url.length - 1)
  }
  return isPresent(url) ? url.substring(url.lastIndexOf('/') + 1) : ''
}

export const getURLOfSocialNetwork = json => {
  return (isPresent(json) && isPresent(JSON.parse(json))) ? JSON.parse(json).url : ''
}

export const isURLPresent = socialNetwork => {
  return (
    isPresent(socialNetwork.values) &&
    isPresent(getURLOfSocialNetwork(socialNetwork.values))
  )
}
