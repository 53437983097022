import { updateExternalConnectionsApi } from './Api'
import { didUpdateContent } from 'src/components/Edit/Redux/Actions'

export function updateExternalConnections(links) {
  return dispatch => {
    return updateExternalConnectionsApi(links).then(response => {
      dispatch(didUpdateContent('external_connections', response.data))
    })
  }
}
