import React from 'react'
import SocialIconsParent from './SocialIcons/SocialIconsParent'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { isPresent } from 'src/common/ObjectHelper'

function Contact({
  printableAddress,
  phoneNumber,
  email,
  showSocialIcons,
  showAddress,
  inEditMode,
}) {
  let address = ['', '']
  if (isPresent(printableAddress)) {
    address = printableAddress.split(/, (.+)/)
  }

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <Typography variant={'body2'}>{phoneNumber}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body2'}>{email}</Typography>
      </Grid>
      {showAddress && (
        <div>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Typography variant={'body2'}>{address[0]}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body2'}>{address[1]}</Typography>
          </Grid>
        </div>
      )}
      {showSocialIcons && (
        <Grid item xs={12}>
          <SocialIconsParent inEditMode={inEditMode} />
        </Grid>
      )}
    </Grid>
  )
}

Contact.propTypes = {
  printableAddress: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  showSocialIcons: PropTypes.bool,
  showAddress: PropTypes.bool,
  inEditMode: PropTypes.bool,
}

Contact.defaultProps = {
  showSocialIcons: false,
  showAddress: false,
  inEditMode: false,
}

export default Contact
