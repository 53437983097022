import { createSelector } from 'reselect'
import { isPresent } from 'src/common/ObjectHelper'
import { getCompanyProfile } from '../../CompanyProfile/Data'

export const getExternalConnections = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    if (
      isPresent(companyProfile.external_connections) &&
      isPresent(companyProfile.external_connections.data)
    ) {
      return companyProfile.external_connections.data
    } else {
      return []
    }
  }
)

export const getSocialNetworks = createSelector(
  state => getExternalConnections(state),
  externalConnections => {
    if (isPresent(externalConnections)) {
      const socialFilter = ['facebook', 'twitter', 'instagram']
      let socialNetworks = []
      externalConnections
        .filter(externalConnection => socialFilter.includes(externalConnection.name))
        .forEach(externalConnection => {
          socialNetworks.push(externalConnection)
        })
      return socialNetworks
    } else {
      return []
    }
  }
)
