import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'
import CompleteModal from './CompleteContent/CompleteModal'
import {
  formatDate,
  formatStartAndEndTime,
} from 'src/common/StartEndDateTimeParser'
import {
  getServiceStreetAddress,
  getPrintableAddressLine2,
} from '../Redux/ServiceLocation/Data'
import {
  getDisplayName,
  getPhoneNumber,
  getEmail,
} from '../Redux/CustomerInfo/Data'
import { getBusinessHours } from 'src/Resources/CompanyProfile/Data'
import { getOrganization } from 'src/Resources/Organization/Data'
import { getStartTime, getEndTime } from '../Redux/SelectedBookingWindow/Data'

const styles = theme => ({
  page: {
    width: 'calc(100% + 16px)',
    margin: -8,
    minHeight: '100vh',
    backgroundColor: '#2095F3',
  },
  paper: {
    padding: 16,
  },
  paperWrapper: {
    maxWidth: 960,
  },
})

@connect(state => ({
  startTime: getStartTime(state),
  endTime: getEndTime(state),
  addressLine1: getServiceStreetAddress(state),
  addressLine2: getPrintableAddressLine2(state),
  displayName: getDisplayName(state),
  phoneNumber: getPhoneNumber(state),
  organization: getOrganization(state),
  businessHours: getBusinessHours(state),
  email: getEmail(state),
}))
@withStyles(styles)
export default class Complete extends PureComponent {
  render() {
    const {
      classes,
      startTime,
      endTime,
      addressLine1,
      addressLine2,
      displayName,
      phoneNumber,
      email,
      organization,
      businessHours,
    } = this.props
    var printableDate = formatDate(startTime),
      printableTime = '@ ' + formatStartAndEndTime(startTime, endTime)

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.page}
      >
        <Grid item xs={12} className={classes.paperWrapper}>
          <Paper className={classes.paper}>
            <CompleteModal
              organization={organization}
              businessHours={businessHours}
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              orderTime={[printableDate, printableTime]}
              customerInfo={[displayName, phoneNumber, email]}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}
