import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { isPresent } from 'src/common/ObjectHelper'

const styles = {
  header: {
    paddingBottom: 16,
    paddingTop: 120, //This margin and padding top pair is for anchor tag compensating for the sticky header
    marginTop: -120,
  },
  editIcon: {
    position: 'absolute',
    right: 8,
  },
  sectionTitle: {
    fontWeight: 'bold',
  },
}

function SectionHeader({ classes, id, title, children }) {
  return (
    <Grid
      container
      id={id}
      justify="space-between"
      alignItems="center"
      className={
        isPresent(id) || isPresent(title) ? classes.header : classes.editIcon
      }
    >
      <Grid item xs>
        <Typography variant="h6" className={classes.sectionTitle}>{title}</Typography>
      </Grid>
      {isPresent(children) && <Grid item>{children}</Grid>}
    </Grid>
  )
}

SectionHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default withStyles(styles)(SectionHeader)
