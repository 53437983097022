import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const styles = {
  th: {
    whiteSpace: 'nowrap',
    maxWidth: 4,
  },
  item: {
    paddingLeft: 0,
    paddingTop: 10,
    paddingBottom: 10,
  },
}

function OrderTableRow({ classes, children, icon }) {
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.th}>
        {icon}
      </TableCell>
      <TableCell align="left" className={classes.item}>
        {children}
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(OrderTableRow)
