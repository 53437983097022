import ExecutionEnvironment from './ExecutionEnvironment'

export function isIE() {
  if (ExecutionEnvironment.onServer) return false

  let msie = /msie/.test(navigator.userAgent.toLowerCase())
  let trident = /trident.*rv\:11\./.test(navigator.userAgent.toLowerCase())

  return msie > 0 || trident > 0
}
