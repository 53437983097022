import { createSelector } from 'reselect'
import { receivedServerData } from 'data-fetcher'

export const CUSTOMER_INFO = 'customer'

export const updateCustomerInfoState = data => receivedServerData(data, CUSTOMER_INFO)

export const getCustomerInfo = createSelector(
  state => state[CUSTOMER_INFO],
  customer => {
    return customer || {}
  }
)

export const getFirstName = createSelector(
  getCustomerInfo,
  customer => {
    return customer.first_name || ''
  }
)

export const getLastName = createSelector(
  getCustomerInfo,
  customer => {
    return customer.last_name || ''
  }
)

export const getDisplayName = createSelector(
  getCustomerInfo,
  customer => {
    return customer.display_name || ''
  }
)

export const getPhoneNumber = createSelector(
  getCustomerInfo,
  customer => {
    return customer.mobile_number || ''
  }
)

export const getEmail = createSelector(
  getCustomerInfo,
  customer => {
    return customer.email || ''
  }
)