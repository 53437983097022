import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Divider } from '@material-ui/core'

const styles = {
  divider: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  content: {
    marginTop: 10,
    marginBottom: 10,
    position: 'relative',
  },
}

function SectionLayout({ classes, headerComponent, bodyContent, showDivider }) {
  return (
    <Fragment>
      {headerComponent}
      <div className={classes.content}>{bodyContent}</div>
      {showDivider && (
        <div className={classes.divider}>
          <Divider />
        </div>
      )}
    </Fragment>
  )
}

SectionLayout.propTypes = {
  headerComponent: PropTypes.element,
  bodyContent: PropTypes.element.isRequired,
  showDivider: PropTypes.bool,
}

SectionLayout.defaultProps = {
  showDivider: true,
}

export default withStyles(styles)(SectionLayout)
