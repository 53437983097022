import React, { PureComponent } from 'react'
import { Grid } from '@material-ui/core'
import Section from '../../CompanyProfile/Sections/Section'
import OrderSummaryComplete from './OrderSummaryComplete'
import { connect } from 'react-redux'
import {
  getServiceStreetAddress,
  getPrintableAddressLine2,
} from 'src/components/Redux/ServiceLocation/Data'
import {
  getDisplayName,
  getPhoneNumber,
  getEmail,
} from 'src/components/Redux/CustomerInfo/Data'
import {
  getFormattedStartTime,
  getFormattedEndTime,
  getFormattedDate,
} from 'src/components/Redux/SelectedBookingWindow/Data'

@connect(state => ({
  startTime: getFormattedStartTime(state),
  endTime: getFormattedEndTime(state),
  date: getFormattedDate(state),
  addressLine1: getServiceStreetAddress(state),
  addressLine2: getPrintableAddressLine2(state),
  customerName: getDisplayName(state),
  phoneNumber: getPhoneNumber(state),
  email: getEmail(state),
}))
export default class OrderSummarySection extends PureComponent {
  render() {
    const {
      classes,
      addressLine1,
      addressLine2,
      startTime,
      endTime,
      date,
      customerName,
      phoneNumber,
      email,
      justify,
      showDivider,
    } = this.props
    return (
      <Grid container justify={justify}>
        <Grid item>
          <Section title="Appointment Summary" showDivider={false}>
            <OrderSummaryComplete
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              date={date}
              time={startTime + ' - ' + endTime}
              customerName={customerName}
              phoneNumber={phoneNumber}
              email={email}
            />
          </Section>
        </Grid>
      </Grid>
    )
  }
}
