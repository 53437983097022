import React, { Fragment } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { navigate } from 'gatsby'

const styles = {
  alignCenter: {
    textAlign: 'center',
    marginBottom: 8
  },
}
function DoneSection({ classes }) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography
          align="center"
          variant="caption"
          className={classes.caption}
        >
          Info will be sent to provided email
        </Typography>
      </Grid>
      <Grid item xs className={classes.alignCenter}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => navigate('/')}
        >
          Done
        </Button>{' '}
      </Grid>
    </Fragment>
  )
}

export default withStyles(styles)(DoneSection)
