import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import CanEditSectionHeader from './Headers/CanEditSectionHeader'
import SectionLayout from './SectionLayout'
import SectionHeader from './Headers/SectionHeader'
import { LongAddContentButton, CancelSaveButtons, EditAndDelete } from 'housecall-ui'
import { withStyles } from '@material-ui/core/styles'
import { isPresent } from 'src/common/ObjectHelper'
import { TextField } from '@material-ui/core'

const styles = {
  topPadding: {
    paddingTop: 16,
  },
  editWithoutSectionTitle: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
}

function Section({
  classes,
  id,
  title,
  children,
  showDivider,
  canEdit,
  isEditing,
  onSaveClick,
  isSaving,
  onEditClick,
  onCancelClick,
  onDeleteClick,
  showAddButton,
  addButtonText,
  onAddButtonClick,
  deleteEnable,
  disabled,
  canEditHeader,
  onChangeTitle,
}) {
  let headerComponent
  if (canEdit && canEditHeader && isEditing) {
    headerComponent = (
      <TextField
        variant="outlined"
        onChange={event => {
          if (isPresent(onChangeTitle)) {
            onChangeTitle(event)
          }
        }}
        defaultValue={title}
        autoFocus
      />
    )
  } else if (canEdit && isPresent(title)) {
    headerComponent = (
      <CanEditSectionHeader
        title={title}
        id={id}
        isEditing={isEditing}
        onEditClick={() => {
          if (isPresent(onEditClick)) {
            onEditClick()
          }
        }}
        deleteEnable={deleteEnable}
        onDeleteClick={() => {
          if (isPresent(onDeleteClick)) {
            onDeleteClick()
          }
        }}
      />
    )
  } else if (canEdit) {
    headerComponent = (
      <div className={classes.editWithoutSectionTitle}>
        <EditAndDelete
          canDelete={deleteEnable}
          canEdit={canEdit}
          editting={isEditing}
          onEditClick={() => onEditClick()}
          onDeleteClick={() => onDeleteClick()}
        />
      </div>
    )
  } else {
    headerComponent = <SectionHeader title={title} id={id} />
  }

  return (
    <SectionLayout
      showDivider={showDivider}
      headerComponent={headerComponent}
      bodyContent={
        <Fragment>
          {children}
          {showAddButton && (
            <div className={classes.topPadding}>
              <LongAddContentButton
                buttonText={addButtonText}
                onClick={() => {
                  if (isPresent(onAddButtonClick)) {
                    onAddButtonClick()
                  }
                }}
              />
            </div>
          )}
          {canEdit && isEditing && (
            <div className={classes.topPadding}>
              <CancelSaveButtons
                isSaving={isSaving}
                onCancelClick={() => {
                  if (isPresent(onCancelClick)) {
                    onCancelClick()
                  }
                }}
                onSaveClick={() => {
                  if (isPresent(onSaveClick)) {
                    onSaveClick()
                  }
                }}
                disabled={disabled}
              />
            </div>
          )}
        </Fragment>
      }
    />
  )
}

Section.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  showDivider: PropTypes.bool,
  showAddButton: PropTypes.bool,
  canEdit: PropTypes.bool,
  isSaving: PropTypes.bool,
  isEditing: PropTypes.bool,
  onEditClick: PropTypes.func,
  onAddClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  deleteEnable: PropTypes.bool,
  disabled: PropTypes.bool,
  canEditHeader: PropTypes.bool,
  onChangeTitle: PropTypes.func,
}

Section.defaultProps = {
  id: '',
  title: '',
  showDivider: true,
  canEdit: false,
  isEditing: false,
  isSaving: false,
  showAddButton: false,
  deleteEnable: false,
  canEditHeader: false,
}

export default withStyles(styles)(Section)
