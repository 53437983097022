import { createSelector } from 'reselect'
import { clearServerData, receivedServerData } from 'data-fetcher'
import { formatDate, formatTime } from 'src/common/StartEndDateTimeParser'
export const SELECTED_BOOKING_WINDOW = 'selectedBookingWindow'

export const updateSelectedBookingWindowState = data =>
  receivedServerData(data, SELECTED_BOOKING_WINDOW)

export const clearSelectedBookingWindowState = () =>
  clearServerData(SELECTED_BOOKING_WINDOW)

export const getSelectedBookingWindow = createSelector(
  state => state[SELECTED_BOOKING_WINDOW],
  selecetedBookingWindow => {
    return selecetedBookingWindow || {}
  }
)

export const getStartTime = createSelector(
  getSelectedBookingWindow,
  selecetedBookingWindow => {
    return selecetedBookingWindow.start_time || ''
  }
)

export const getEndTime = createSelector(
  getSelectedBookingWindow,
  selecetedBookingWindow => {
    return selecetedBookingWindow.end_time || ''
  }
)

export const getFormattedStartTime = createSelector(
  getSelectedBookingWindow,
  selecetedBookingWindow => {
    return formatTime(selecetedBookingWindow.start_time) || ''
  }
)

export const getFormattedEndTime = createSelector(
  getSelectedBookingWindow,
  selecetedBookingWindow => {
    return formatTime(selecetedBookingWindow.end_time) || ''
  }
)

export const getFormattedDate = createSelector(
  getStartTime,
  startTime => {
    return formatDate(startTime) || ''
  }
)